<template>
  <v-select
    :items="getTypesSelect"
    label="Niveis"
    v-model="valor"
    required
  ></v-select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NiveisPicker",
  props: ["value"],
  computed: {
    ...mapGetters(["getTypesSelect"]),
    nivel: {
      get() {
        return this.$store.state.niveis.nivel;
      },
      set(value) {
        this.$store.dispatch("setNivel", value);
        this.$emit("nivel", value);
        this.$emit("input", value);
      },
    },
    valor: {
      get() {
        return this.value;
      },
      set(evento) {
        this.$emit("input", evento);
      },
    },
  },
};
</script>

<style>
</style>