<template>
  <div class="userCRUD">
    <v-card v-if="formUsr || usuario.id">
      <v-card-title>
        {{ usuario.id ? "Edição Usuário" : "Cadastro de Usuário" }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="usuario.name"
          :counter="255"
          label="Nome"
          required
        ></v-text-field>
        <v-text-field
          v-model="usuario.userName"
          :counter="255"
          label="Usuário"
          required
        ></v-text-field>
        <v-text-field
          v-show="!usuario.id"
          v-model="usuario.password"
          :counter="255"
          label="Senha"
          required
        ></v-text-field>
        <SelectNiveis v-model="usuario.type" />
        <SelectGroup v-model="usuario.groupId" />
        <v-text-field
          v-model="usuario.description"
          :counter="255"
          label="Descrição"
        ></v-text-field>

        <v-checkbox
          v-model="usuario.enabled"
          label="Ativo"
          required
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-4" @click="clearObj"
          >Limpar Campos</v-btn
        >
        <v-btn color="success" class="mr-4" @click="save(usuario)">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="div d-flex justify-end ma-2" v-else>
      <v-btn  color="success" @click="formUsr = true" >Cadastrar</v-btn>
    </div>
    <div class="tbl mt-1">   
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisa"
        hide-details
      ></v-text-field>
      <v-data-table
      :headers="headers"
      :items="usuarios"
      :search="search"
      :items-per-page="-1"
    >
      <template v-slot:item.picture="{ item }">
        <img :src="item.picture" width="100" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editar(item)">mdi-pencil</v-icon>
        <v-icon small @click="deletar(item)"> mdi-delete </v-icon>
        <v-icon small class="ml-2" @click="changePass(item)"> mdi-key </v-icon>
      </template>
    </v-data-table>
    </div>
    <DialogPass v-model="dialogPass" :user="usuario" @password="savePass"/>
  </div>
</template>

<script>
import SelectNiveis from "./SelectNiveisForm";
import SelectGroup from "./SelectGroupForm";
import DialogPass from './DialogPass.vue'

export default {
  data() {
    return {
      formUsr:false,
      search:"",
      usuario: {
        id: null,
        enabled: true,
      },
      dialogPass: false,
    };
  },
  computed: {
    workGroup: {
      get() {
        return this.$store.getters.workGroup;
      },
    },
    usuarios: {
      get() {
        return this.$store.getters.getUsers;
      },
    },
    headers: {
      get() {
        return this.$store.getters.getTableHeader;
      },
    },
  },
  components: {
    SelectNiveis,
    SelectGroup,
    DialogPass
  },
  methods: {
    get() {
      this.edicao = false;
      this.$store.dispatch("loadUsers", true);
      this.$store.dispatch("loadUsersTypes");
    },
    save(objeto) {
      this.$store.dispatch("saveUser", objeto);
    },
    editar(event) {
      this.usuario = event;
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    changePass(item) {
      // this.$router.push({
      //   name: "MyUser",
      //   params: { editar: { ...item } },
      // });
      this.usuario = item;
      this.dialogPass = true;
    },
    savePass(newPassword){
      this.usuario.password = newPassword;
      this.$store.dispatch("changePass", this.usuario);
      this.dialogPass = false;
    },
    delet(event) {
      this.$store.dispatch('deleteUser', event)
    },
    groupSelected() {
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  created() {
    this.get();
  },
};
</script>

<style>
</style>