<template>
  <div>
    <v-dialog v-model="value" persistent max-width="400">
      <v-card>
        <v-card-title> <p class="text-truncate">Nova senha:  {{ user.userName }} - {{user.name}}</p> </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="password"
                :counter="255"
                label="Nova Senha"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="orange darken-1" text @click="change"> Trocar Senha </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogPass",
  props: ["value", "user"],
  data() {
    return {
      password: "",
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
    },
    change(){
        this.$emit("password", this.password);
        this.close();
    }
  },
};
</script>

<style scoped>
</style>